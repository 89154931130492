<template>
  <div>
    <div style='position: fixed; left: 0; width: 33%;  background-color: #f4f5f7; z-index: -2;'></div>
    <div style='position: fixed; top: 0; right: 0; width: 33%; height: 100%; background-color: #f4f5f7; z-index: -1;'></div>
    <div class="pageContainer" >
    <feed-nav/>
    <div style="width:100%;">
      <div>
        <div style="margin-left: 57px; margin-top: 50px;  width: 895px; margin-bottom: 100px;">
          <template v-if="user.seller.isSeller && false">
            <transition name="fade">
              <div style="position: relative">
                <router-link to="/destek/bionluk-tan-10-10-luk-yarisma-109">
                  <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/uploads/ticket/7718923f-9488-4a37-a381-9bddd4e069ca.jpg">
                </router-link>
              </div>
            </transition>
          </template>
          <!--
          <template v-if="user.seller.isSeller">
            <transition name="fade">
              <div style="position: relative">
                <a href="https://artboard.me/bionluk" target="_blank">
                  <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/uploads/message/b3539b2d-392b-47cb-95bb-10cad5363845.png">
                </a>
              </div>
            </transition>
          </template>
          <template v-if="user.seller.isSeller">
             <transition name="fade">
               <div style="position: relative">
                 <router-link to="/efsaneyarisma">
                   <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/uploads/message/519949db-051f-4844-9fed-765242a088bc.jpeg">
                 </router-link>
               </div>
             </transition>
           </template>

          <template v-if="!user.seller.isSeller">
            <transition name="fade">
              <div style="position: relative">
                <router-link to="/sl/23_nisan">
                  <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/uploads/message/badca614-7974-41d5-9818-6f50be163a71.jpeg">
                </router-link>
              </div>
            </transition>
          </template>

        <transition name="fade">
          <div style="position: relative">
            <router-link to="/sl/kadinlar_gunu">
              <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/site/general/banner-march-8.png">
            </router-link>
          </div>
        </transition>

      <transition name="fade">
        <div v-if="!user.info.clicked_current_banner" style="position: relative">
          <div @click="clicked('current_banner')" class="banner-delete-icon-area">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="banner-delete-icon">
          </div>
          <a href="https://www.eventbrite.com/e/bionluk-freelancer-bulusmas-vol-2-tickets-76437317073" target="_blank">
            <img style="border-radius: 10px;box-shadow: 0 20px 20px 0 #e9ebf0;width: 100%; height: auto; margin-bottom:70px;" src="https://gcdn.bionluk.com/site/cicons/free-bannerx.png">
          </a>
        </div>
      </transition>
-->
          <div style=" display: flex; height: 180px; justify-content: space-between">
            <div style="display: flex">
              <div v-if="uploading" style="display:flex; align-items:center; justify-content:center;width: 88px; height: 88px; border-radius: 50%; border:2px solid #eaeaea">
                <loader style="height: 25px; width: 25px;"></loader>
              </div>
              <img v-else style="width: 88px; height: 88px; border-radius: 50%; border:2px solid #eaeaea" :src="$store.state.user.avatar_url"/>
              <div :style="!uploading ? 'visibility : visible' : 'visibility : hidden'" v-if="$store.state.user.avatar_url === 'https://gcdn.bionluk.com/uploads/general/No_Avatar.png'" @click="setImage()" class="avatar-upload-area">
                <input id="files" accept="image/*" type="file" style="display: none;"  @change="onFileChange"/>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-add-or-plus.svg"  onload="SVGInject(this)" class="avatar-upload-icon">
              </div>
              <div style="margin-left: 25px; height: 90px; display: flex; flex-direction: column; justify-content: center;">
                <p style="font-size: 34px; font-weight: 300; color: #4b4f52; ">Merhaba <span style="font-weight: bold; color: #2d3640">{{$store.state.user.firstname || $store.state.user.username}}</span> 👋</p>
                <p  style="font-size: 18px; font-weight: 300; color: #4b4f52; line-height: 2.3;">
                  <span v-if="Date.now() - user.created_at > 1814400">Bionluk'a tekrar hoş geldin!</span>
                  <span v-else>Geleceğin çalışma modeline hoş geldin!</span>
                </p>

              </div>
            </div>

            <template v-if="user.seller.isSeller || user.requestCount">
              <div v-if="user.seller.isSeller && !user.info.clicked_bicoin" class="top-right-box" style=" width: 285px;height: 100px;">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/bicoin.svg">
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 10px;">
                  <p style="color:#2d3640; font-size: 16px; font-weight: bold">bicoin'leri Topla</p>
                  <p style="color:#2d3640; font-size: 10px;line-height: 1.4; margin-top: 5px; margin-bottom: 5px;">Öncelik, Zaman ve Görünürlük Kazan</p>
                  <p @click="clicked('bicoin')" class="green-link">Detaylı Bilgi</p>
                </div>
              </div>

              <div v-if="user.seller.isSeller && user.info.clicked_bicoin && !showSellerBox3" class="top-right-box" style="position: relative;  width: 270px;height: 100px;">
                <template v-if="user.info.gsm_is_activated && user.info.f_t_used && user.info.f_t_ending_at && user.info.f_t_status !== 1">
                  <div>
                    <img src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg">
                  </div>
                  <div style="display: flex; flex-direction: column; margin-left: 18px;">
                    <div v-if="user.info.f_t_status === 3" class="green-small-area">Aktif <span style="text-transform: capitalize; padding-left: 3px;"> {{leftDay}}</span></div>
                    <div v-if="user.info.f_t_status === 4" style="margin-left: 17px; padding-left: 5px; padding-right: 5px;" class="line-not-active">Süresi Doldu</div>
                    <div v-if="user.info.f_t_status === 2" style="margin-left: 17px" class="line-not-active">Aktif Değil</div>
                    <div v-if="user.info.f_t_status !== 4" @click="showLineToolTip" @focusout="handleFocusOut"  style="display: flex; margin-top: 12px;">
                      <div  id="line-tool-tip" class="line-tool-tip" tabindex="0" :style="openShowLineToolTip ? 'visibility: visible;' : 'visibility:hidden;'">
                        <p style="padding-left:20px;font-size: 12px; font-weight: 300; margin-top: 15px;">Sana Ulaşılacak Telefon</p>
                        <p style="padding-left:20px;font-size: 14px; font-weight: bold;">0 (212) 909 - 23 - 10</p>
                        <p style="margin-top:10px;padding-left:20px;font-size: 12px; font-weight: 300;">Dahili Numaran</p>
                        <p style="padding-left:20px;font-size: 14px; font-weight: bold;">{{user.info.f_t_line}}</p>
                        <p @click="copyLine" style="color:#fe5b5c;margin-top:10px;padding-left:20px;font-size: 12px; font-weight: normal; cursor: pointer">[Numaranı Kopyala]</p>
                      </div>
                      <p style="color:#8b95a1; font-size: 12px; font-weight: bold; line-height: 1.3">Numaramı Göster</p>
                      <img  src="https://gcdn.bionluk.com/site/cicons/ic-info.svg" onload="SVGInject(this)" class="right-info-icon">
                    </div>
                    <div v-else style="margin-top: 2px;margin-left: 18px;">
                      <span @click="extend('extend line small box')" class="line-extend" style="color: #2d3640; text-align: center">[süreyi uzat]</span>
                    </div>

                  </div>
                  <div style="position:relative;">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'extend line small box')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,'extend line small box')"
                      to="/settings">
                      <div style=" position: absolute; top:-42px; left: 10px;">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-settings.svg" onload="SVGInject(this)" class="right-settings-icon">
                      </div>
                    </router-link>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <img style="height: 54px; width: 54px;" src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg">
                  </div>
                  <div style="display: flex; flex-direction: column; margin-left: 18px;">
                    <p style="color: #2d3640; font-size: 16px; font-weight: bold">Freelancer Hattı</p>
                    <p style="color: #2d3640; font-size: 10px; line-height: 1.4; margin-top: 3px; margin-bottom: 5px;">İlk ay ücretsiz</p>
                    <p style="color: #00a575; font-size: 12px; cursor: pointer;" @click="openFreelancerLineFaqModal()">Detaylı Bilgi</p>
                  </div>
                  <div style="position:relative;">
                    <router-link
                      @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'open line small box')"
                      @click.native="handleGlobalClick($store.state.previousComponentPage,'open line small box')"
                      to="/settings">
                      <div style=" position: absolute; top:-42px; left: 10px;">
                        <img src="https://gcdn.bionluk.com/site/cicons/ic-settings.svg" onload="SVGInject(this)" class="right-settings-icon">
                      </div>
                    </router-link>
                  </div>
                </template>
              </div>

              <div v-if="!user.seller.isSeller && user.requestCount" class="top-right-box" style="width: 288px;height: 120px;">
                <div>
                  <img src="https://gcdn.bionluk.com/site/cicons/il-best-freelancers.svg">
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 20px;">
                  <div  v-if="onlineUsers.count" style="display: flex; margin-left: 5px">
                    <div v-for="onlineUser in onlineUsers.users ">
                      <div>
                        <router-link
                          @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'bia up small box')"
                          @click.native="handleGlobalClick($store.state.previousComponentPage,'bia up small box')"
                          :to="'/'+onlineUser.username">
                          <img :alt="onlineUser.username" style="border:1px solid #bfc8d2; width: 27px; height: 27px; border-radius: 50%; margin-left: -5px;" :src="onlineUser.avatar_url">
                        </router-link>
                      </div>
                    </div>
                    <div style="width: 28px;height: 28px;border: solid 1px #ffffff;background-color: #eaedf2; border-radius: 50%; font-size: 12px; margin-left: -5px; display: flex; align-items: center; justify-content: center;letter-spacing: -0.43px; color: #2d3640; font-weight: bold">
                      +{{onlineUsers.x_count}}
                    </div>
                  </div>
                  <div v-else style="display: flex">
                    <div class="online-users-loader">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                    <div class="online-users-loader-2">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                    <div class="online-users-loader-3">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                    <div class="online-users-loader-2">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                    <div class="online-users-loader-2">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                    <div class="online-users-loader-3">
                      <img style="width: 28px; height: 28px; border-radius: 50%; margin-left: -5px;" src=" https://gcdn.bionluk.com/uploads/general/No_Avatar.png">
                    </div>
                  </div>
                  <p style="margin-top:3px; margin-bottom:10px;color: #00a575;font-size: 14px"><span style="font-weight: bold"><span v-if="onlineUsers.count">{{onlineUsers.count}} </span> freelancer</span> çevrimiçi</p>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'up small box')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'up small box')"
                    to="/benim-isim-acil" class="small-pink-button">Freelancer Bul</router-link>
                </div>
              </div>
            </template>
          </div>

          <div v-if="user.seller.isSeller && user.seller.gigCount > 0">
            <BuyerRequest :list="$store.state.openBuyerRequest" />
          </div>

          <!-- BEGIN for non-freelancers SEARCH -->
          <template>
            <div v-if="!user.seller.isSeller">
              <div class="mainpage-search-container">
                <input

                  v-model="searchTerm"
                  @keyup.enter="inputEnter(searchTerm)"
                  @keyup="keyUp()"
                  @keydown="keyDown()"
                  type="text"
                  name="search"
                  class="mainSearch"
                  autocomplete="off"
                  data-lpignore="true"
                  placeholder="Neye ihtiyacın var?"/>
                <loader v-if="isSearching" style="position: absolute; right: 275px;height: 25px; width: 25px;"></loader>

                <button @click="searchGigs(searchTerm, 'today button')" class="mainpage-search-button" style="margin-left:30px;">
                  <p class="search-button-text">Arama Yap</p>
                </button>
                <div class="search-result-container" v-if="categorySearchResults && searchResultDone" v-click-away="() =>  searchResultDone = false">
                  <div class="search-result-header">
                    <p style="font-size: 14px;color: #737878;"><span style="font-weight: 500; color: #2d3640;">Kategoriler</span>de Ara</p>
                  </div>
                  <div v-if="categorySearchResults.length > 0" class="search-result-users-container" style="padding-left: 38px;">
                    <div class="search-result-user"
                         @click="routeSearchDeedsPage(categorySearchResult, searchTerm)"
                         style="cursor:pointer; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; margin-bottom: 10px;"
                         v-for="categorySearchResult in categorySearchResults">
                      <p style="font-size: 14px;line-height: 2.29;text-align: left;color: #2d3640;">{{categorySearchResult.name}}<span style="color: #737878;"> kategorisinde "{{searchTerm}}"</span>
                      </p>
                    </div>
                  </div>
                  <!--users-->
                  <div class="search-result-header">
                    <p style="font-size: 14px;color: #737878;"><span style="font-weight: 500; color: #2d3640;">Freelancerlar</span>da Ara</p>
                    <div   @click="routeSearchUserPage(searchTerm)"
                                 style="display: flex; align-items: center; border: solid 1px #2d3640; padding:  8px 19px;cursor: pointer">
                      <p style="font-size: 12px;  color: #2d3640;">Tüm Freelancerlarda Ara</p>
                    </div>
                  </div>
                  <div v-if="userSearchResults.length > 0" class="search-result-users-container">
                    <div class="search-result-user"
                         @click="goToUserProfile(userSearchResult.username)"
                         style="cursor:pointer; display: flex; flex-direction: row; justify-content: flex-start; align-items: center; margin-bottom: 10px;"
                         v-for="userSearchResult in userSearchResults">
                      <img style="width: 36px; height: 36px; border-radius: 50%; margin-right: 14px;"
                           :src="userSearchResult.avatarURL"/>
                      <p style="font-size: 14px;line-height: 2.29;text-align: left;color: #2d3640;">{{userSearchResult.username}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; height: 36px; align-items: center;  margin-bottom: 60px; ">
                <span style="color:#8b95a1; font-size: 16px;">Popüler Aramalar</span>
                <div style="display: flex; margin-left: 15px;">
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/seslendirme"><div class="search-tag">Seslendirme</div></router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/logo-tasarimi"><div class="search-tag">Logo Tasarımı</div></router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/web-yazilim"><div class="search-tag">Web Yazılım</div></router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/ceviri"><div class="search-tag">Çeviri</div></router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/wordpress"><div class="search-tag">Wordpress</div></router-link>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'popular tag')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'popular tag')"
                    to="/freelancer-bul/animasyon-3d"><div class="search-tag">Animasyon & 3D</div></router-link>
                </div>
              </div>

              <!-- BEGın LAST seen items -->
              <transition name="fade">
                <div v-if="$store.state.lastSeenItems && $store.state.lastSeenItems.length" style="margin-bottom:70px">
                  <div style="display: flex; justify-content: space-between; ">
                    <p class="section-header-today">Son <span>Gezdiklerin</span></p>
                    <p class="tumu" @click="deleteLastSeen('all')">Hepsini Temizle</p>
                  </div>
                  <div class="last-seen-box-area">
                    <div v-for="lastItem in $store.state.lastSeenItems.slice(0, 3)" class="last-seen-box-container">
                      <div class="last-seen-box" :key="lastItem.id">
                        <div class="last-seen-header">
                          <div class="up-text">{{setLastItem('upText', lastItem)}}</div>
                          <div class="last-seen-delete" @click="deleteLastSeen(lastItem.id)">
                            <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="last-seen-delete-icon">
                          </div>

                        </div>
                        <div class="last-seen-profile">
                          <router-link
                            @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'last seen items')"
                            @click.native="handleGlobalClick($store.state.previousComponentPage,'last seen items')"
                            :to="lastItem.type === 'profile' ? '/'+lastItem.username : lastItem.slug_url">
                            <img :src="lastItem.avatar_url" :class="lastItem.type === 'profile' ? 'last-seen-avatar' : 'last-seen-gig-img'">
                          </router-link>
                          <div class="last-seen-info-area">

                            <router-link v-if="lastItem.type === 'profile'"
                                         @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'last seen items')"
                                         @click.native="handleGlobalClick($store.state.previousComponentPage,'last seen items')"
                                         :to="'/'+lastItem.username">
                              <p class="m-title">{{setLastItem('title', lastItem)}}</p>
                            </router-link>
                            <p v-if="lastItem.type === 'profile'" class="m-sub-title">{{setLastItem('subTitle', lastItem)}}</p>

                            <div class="down-text-area" v-if="lastItem.response_time && lastItem.type === 'profile'">
                              <div class="down-text-icon-area">
                                <img src="https://gcdn.bionluk.com/site/cicons/ic-timer.svg" onload="SVGInject(this)" class="down-text-icon">
                              </div>
                              <p class="down-text">Ort. Yanıt Süresi <span> {{setLastItem('responseTime', lastItem)}}</span></p>
                            </div>

                            <template v-else-if="lastItem.type === 'gig'" class="down-text-area">
                              <router-link
                                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'last seen items')"
                                @click.native="handleGlobalClick($store.state.previousComponentPage,'last seen items')"
                                :to="lastItem.slug_url">
                                <p class="m-title-gig">{{lastItem.title}}</p>
                              </router-link>
                              <router-link
                                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'last seen items')"
                                @click.native="handleGlobalClick($store.state.previousComponentPage,'last seen items')"
                                :to="'/'+lastItem.username">
                                <p class="green-text" style="margin-top: 0">{{lastItem.username}}</p>
                              </router-link>
                            </template>

                            <template v-else-if="lastItem.type === 'category'" class="down-text-area">
                              <router-link
                                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'last seen items')"
                                @click.native="handleGlobalClick($store.state.previousComponentPage,'last seen items')"
                                :to="lastItem.slug_url">
                                <p class="m-title">{{lastItem.title}}</p>
                              </router-link>
                              <p class="green-text" ><span>{{lastItem.freelancer_count}}</span> Freelancer</p>
                              <div class="down-text-area" style="display: block">
                                <p class="down-text">hizmet veriyor</p>
                              </div>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- END last seen items -->
            </div>
          </template>
          <!-- END for non-freelancers -->


          <!-- BEGIN Açık Siparişler for non-freelancers -->
          <transition name="fade">
            <div v-if="user.openOrders && user.openOrders.length">
              <p class="section-header-today" >Açık <span>Siparişlerin</span></p>
              <OrderBox :list="user.openOrders"></OrderBox>
              <div style="height: 70px"></div>
            </div>
          </transition>
          <!-- END Açık Siparişler for non-freelancers -->


          <!-- BEGIN Bunlara da ihtiyacın olabilir -->
          <transition name="fade">
            <div v-if="user.openOrders && user.openOrders.length">
                <p class="section-header-today" style="margin-bottom: 0">Bunlara da <span>İhtiyacın Olabilir 😉</span></p>
                <p class="section-sub-header-today"><span>{{user.openOrders[0].category_sub_name}}</span> satın alanlar aşağıdaki hizmetleri de satın aldılar. </p>
              <GigBox :impression-u-u-i-d="impressionUUID" type="youMayNeedGigs" :list="$store.state.youMayNeedGigs.slice(0,3)"></GigBox>
              <div style="height: 70px"></div>
            </div>
            <div v-else-if="user.info.lastOrderId">
              <p class="section-header-today" style="margin-bottom: 0">Bunlara da <span>İhtiyacın Olabilir 😉</span></p>
              <p class="section-sub-header-today"><span>{{user.info.lastOrderSubCategoryName}}</span> satın alanlar aşağıdaki hizmetleri de satın aldılar. </p>
              <GigBox :impression-u-u-i-d="impressionUUID" type="youMayNeedGigs" :list="$store.state.youMayNeedGigs.slice(0,3)"></GigBox>
              <div style="height: 70px"></div>
            </div>
          </transition>


          <!-- BEGIN Bunlara da ihtiyacın olabilir -->




          <!-- BEGIN NEW USERS -->
          <!-- orderpayed, is_seller, request_creater-->
          <div v-if="!user.seller.isSeller && !user.requestCount && $store.state.lastSeenItems.length === 0" style="height: 20px"></div>
          <div v-if="!user.seller.isSeller && !user.requestCount" class="Box3Container">
            <div class="Box3">
              <div class="Box3In">

                <p class="Box3Header">Alıcı İsteği <span>Oluştur</span></p>
                <img src="https://gcdn.bionluk.com/site/general/il-buyer-request.svg">
                <p class="Box3Desc">Aradığın hizmeti veya freelancer’ı bulmakta zorlanıyor musun? O zaman sen alıcı isteği oluştur, freelancer’lar seni bulsun!</p>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'welcome box')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'welcome box')"
                  to="/panel/istekler" class="Box3Button">Alıcı İsteği Oluştur</router-link>
              </div>

            </div>
            <div class="Box3">
              <div class="Box3In">

                <p class="Box3Header">Sana En Uygun <span>Freelancer’ı Bul</span></p>
                <img src="https://gcdn.bionluk.com/site/general/il-best-freelancers.svg">
                <p class="Box3Desc">Almak istediğin hizmet için sana en uygun freelancer’ları, birkaç adımda birlikte bulalım.</p>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'welcome box')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'welcome box')"
                  to="/benim-isim-acil" class="Box3Button">Freelancer Bul</router-link>
              </div>

            </div>
            <div class="Box3">
              <div class="Box3In">

                <p class="Box3Header">Freelancer Ol, <span>İstediğin Gibi Çalış</span></p>
                <img src="https://gcdn.bionluk.com/site/general/il-be-freelancer.svg">
                <p class="Box3Desc">Freelancer topluluğumuza katıl, yeteneklerini sergile. Bionluk da sana iş bulsun.</p>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'welcome box')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'welcome box')"
                  to="/freelancer-olmak"  class="Box3Button">Freelancer Ol!</router-link>
              </div>
            </div>
          </div>
          <!-- ENG NEW USERS -->


          <!-- BEGIN MISSING FREELANCERs  -->
          <!-- iş ilanı yok, veya profilinde eksikler var-->
          <div v-if="showSellerBox3" style="height: 20px"></div>
          <div v-if="showSellerBox3" class="Box3Container">
            <div class="Box3">
              <div class="Box3In">
                <p class="Box3Header" style="display:block; width: 230px;">Sana Uygun <span>Alıcı İstekleri</span></p>
                <img style="width: 168px; height: 129px; margin-top: 0;" src="https://gcdn.bionluk.com/site/general/il-buyer-request.svg">
                <p v-if="!user.seller.gigCount" class="Box3Desc" style="height: 113px;">Sana ve yeteneklerine en uygun alıcı isteklerini gösterebilmemiz için, seni tanımamız lazım. Bu yüzden <span style="color: #2d3640; font-weight: 600;">en az 1 adet iş ilanı oluşturmalısın.</span></p>
                <p v-else class="Box3Desc" style="height: 113px;">Bionluk'ta diğer kullanıcılar da istekte bulunabilir. Sana uygun alıcı isteklerine göz atabilir, ilgini çekenlere teklif verebilirsin.</p>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
                  v-if="!user.seller.gigCount" to="/panel/ilanlar/yeni" class="Box3Button">İş İlanı Oluştur</router-link>
                <router-link v-else

                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
                    to="/panel/alici-istekleri" class="Box3Button">Alıcı İsteklerine Git</router-link>
              </div>

            </div>
            <div class="Box3">
              <div class="Box3In">
                <p class="Box3Header" style="position:relative;display:block;height:auto;">Profilini <span>Güçlendir!</span></p>
                <div style="height: 132px">
                  <div class="rainDropcontainer" style="margin-top: 5px;">
                    <div class="firstLayer"></div>
                    <div class="secondLayer"></div>
                    <div class="thirdLayer"></div>
                    <img style="margin-top: 18px; width: 91px; height: 91px;" :src="user.avatar_url" class="freelancerAvatar">
                  </div>
                </div>


                <div class="profile-box-items">
                  <div style="display: flex;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.portfolioCount > 2 ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                    <p :class="user.seller.portfolioCount > 2 ? 'item-text-green' : 'item-text'">Portfolyo <span style="font-style: italic">(En az 3 adet)</span></p>
                  </div>
                  <div style="display: flex;  margin-top: 16px;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.info.title && user.info.title.length > 1 ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                    <p :class="user.info.title && user.info.title.length > 1 ? 'item-text-green' : 'item-text'">Başlık</p>
                  </div>
                  <div style="display: flex;  margin-top: 16px;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.info.brief && user.info.brief.length > 1 ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                    <p :class="user.info.brief && user.info.brief.length > 1 ? 'item-text-green' : 'item-text'">Hakkında</p>
                  </div>
                  <div style="display: flex;  margin-top: 16px;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.skillCount ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                    <p :class="user.seller.skillCount ? 'item-text-green' : 'item-text'">Uzmanı Olduğun Alanlar & Araçlar</p>
                  </div>
                  <div style="display: flex;  margin-top: 16px;">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-verified-badge.svg" onload="SVGInject(this)" :class="user.seller.isRegistered ? 'profile-box-item-ok-green' : 'profile-box-item-ok'">
                    <p :class="user.seller.isRegistered ? 'item-text-green' : 'item-text'">Banka Hesap Bilgilerin</p>
                  </div>
                </div>
                <router-link
                  @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
                  @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
                  to="/settings" class="Box3Button">Profilini Güncelle</router-link>
              </div>

            </div>
            <div class="Box3">
              <div class="Box3In">
                <p class="Box3Header" style="display: block; width: 230px; height: auto">Bionluk<span> Freelancer Hattı</span></p>
                <template v-if="user.info.gsm_is_activated && user.info.f_t_used && user.info.f_t_ending_at && user.info.f_t_status !== 1">
                  <div style="display: flex; align-items: center; justify-content: center; margin-top: 30px;">
                    <img style="width: 36px; height:36px; margin-top: 0;" src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg">
                    <div v-if="user.info.f_t_status === 3" style="margin-left: 17px" class="line-active">Aktif</div>
                    <div v-if="user.info.f_t_status === 2 || user.info.f_t_status === 4" style="margin-left: 17px" class="line-not-active">Aktif Değil</div>
                  </div>
                  <div style="margin-top: 25px; text-align: center">
                    <p style="color: #5e6b79; font-weight: 300; font-size: 14px;">Sana Ulaşılacak Telefon</p>
                    <p style="color: #2d3640; font-weight: bold; font-size: 16px; margin-top: 5px;">0 (212) 909 - 23 - 10</p>
                  </div>
                  <div style="margin-top: 25px; text-align: center">
                    <p style="color: #5e6b79; font-weight: 300; font-size: 14px;">Dahili Numaram</p>
                    <p style="color: #2d3640; font-weight: bold; font-size: 16px; margin-top: 5px;">{{user.info.f_t_line}}</p>
                  </div>
                  <div style="margin-top: 25px; text-align: center;">
                    <p style="color: #5e6b79; font-weight: 300; font-size: 14px;">Geçerlilik Tarihi</p>
                    <p style="color: #2d3640; font-weight: normal; font-size: 16px; margin-top: 5px; display: flex; align-items: center; justify-content: center">{{user.info.f_t_status === 4 ? 'Hattın Süresi Doldu' : endingDate}} <span @click="extend('seller 3 boxes')" class="line-extend">[süreyi uzat]</span></p>
                  </div>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
                    to="/settings" style="margin-top: 55px;"  class="Box3Button">Hat Ayarları</router-link>
                </template>
                <template v-else>
                  <img style="width: 80px; height:80px; margin-top: 30px;" src="https://gcdn.bionluk.com/site/cicons/freelancer-line.svg">
                  <p class="Box3Desc" style="height: 160px; width: 230px; "><span style="color:#2d3640; font-weight: bold">Bionluk Freelancer Hattı</span> sayesinde kişisel numaranı paylaşmaksızın cep telefonun üzerinden tüm Bionluk kullanıcılarıyla güvenle görüşmeye başlayabilirsin. <span style="color: #00a575; font-weight: bold;">Üstelik ilk ay ücretsiz!</span> 😉</p>
                  <router-link
                    @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'seller 3 boxes')"
                    @click.native="handleGlobalClick($store.state.previousComponentPage,'seller 3 boxes')"
                    to="/settings"  class="Box3Button">Freelancer Hattını Aç</router-link>
                </template>
              </div>
            </div>
          </div>
          <!-- ENG MISSING FREELANCERs -->


          <template v-if="user.seller.isSeller && user.seller.gigCount > 0">
            <div style="display: flex; justify-content: space-between; ">
              <p class="section-header-today">Yayındaki <span>İlanların</span></p>
              <router-link
                @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'ownGigs')"
                @click.native="handleGlobalClick($store.state.previousComponentPage,'ownGigs')"
                to="/panel/ilanlar" class="tumu">Tüm İlanların</router-link>
            </div>
            <GigBox :impression-u-u-i-d="impressionUUID" type="ownGigs" :list="null"></GigBox>
            <div style="height: 70px"></div>
          </template>


          <div v-if="blogViews.length" style="display: flex; justify-content: space-between; ">
            <p class="section-header-today" style="margin-bottom: 20px">Bi'<span>Blog</span> 📰</p>
            <router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'Today Generic')"
              @click.native="handleGlobalClick($store.state.previousComponentPage,'Today Generic')"
              to="/blog" class="tumu">Tüm Yazılar</router-link>
          </div>
          <div v-if="blogViews.length" style="display: flex; justify-content: space-between">
            <BlogView v-for="(blogView, index) in blogViews" :blog-view="blogView" :key="blogView.pathToGo + index" />
          </div>
          <div style="height: 70px"></div>

          <div style="display: flex; justify-content: space-between; ">
            <p class="section-header-today" style="margin-bottom: 20px">Bionluk'ta <span> Yapıldı </span> 🧙‍</p>
            <!--
            <router-link
              @contextmenu.native="handleGlobalClick($store.state.componentMap.page,'lastDonePortfolios')"
              @click.native="handleGlobalClick($store.state.previousComponentPage,'lastDonePortfolios')"
              to="/freelancer-vitrin" class="tumu">Tümü</router-link>
              -->
          </div>
          <PortfolioBox :list="$store.state.lastDonePortfolios"></PortfolioBox>

          <div style="height: 60px"></div>

          <!-- lovedFreelancersF SADECE İLANI OLMAYAN NON-FREELANCER'LARA -->
          <template v-if="!user.seller.isSeller || !user.seller.oldGigCount">
            <p class="section-header-today">En Sevilen <span>Freelancerlar 🥰</span></p>
            <FreelancerBox type="lovedFreelancers" :list="$store.state.lovedFreelancers"></FreelancerBox>
            <div style="height: 70px"></div>
          </template>


          <template v-if="!user.seller.isSeller || !user.seller.gigCount">
            <div style="display: flex; justify-content: space-between; ">
              <p class="section-header-today">İlham Verici  <span>İlanlar</span> 💫</p>
            </div>
            <GigBox :impression-u-u-i-d="impressionUUID" type="inspiringGigs" :list="$store.state.inspiringGigs"></GigBox>
            <div style="height: 70px"></div>
          </template>


          <template v-if="user.seller.isSeller && user.seller.gigCount">
            <div style="display: flex; justify-content: space-between; ">
              <p class="section-header-today">Kategorilerinde Yeni <span>Açılan İlanlar</span> 🔔</p>
            </div>
            <GigBox :impression-u-u-i-d="impressionUUID" type="ownSimilarGigs" :list="$store.state.ownSimilarGigs"></GigBox>
            <div style="height: 70px"></div>
          </template>




          <p class="section-header-today">En popüler <span>Hizmetler </span> 🤩</p>
          <SubCategoryBox title="popularServices" :list="$store.state.popularCategories"></SubCategoryBox>






        </div>
      </div>
      <div class="hiddendiv"></div>
    </div>
    </div>
    <div style="width: calc(50% - 570px); height: auto; background-color: #f4f5f7;"></div>
  </div>
</template>

<script>
  import FeedNav from "../../../components/shared/globalSideNav";
  import FreelancerBox from "../../../components/boxSliders/freelancer";
  import GigBox from "../../../components/boxSliders/gig";
  import PortfolioBox from "../../../components/boxSliders/portfoliov2";
  import SubCategoryBox from "../../../components/boxSliders/subCategory";
  import OrderBox from "../../../components/boxSliders/order";
  import helpBox from "../../../components/HelpBox";
  import CompactToday from "../../../components/todayItems/compactToday";
  import BuyerRequest from "../../../components/boxSliders/buyerRequest";

  import { customDateFormat, diffFromNow }  from '@/utils/dateFns'
  import BlogView from "@/pages/components/BlogView";
  import Api from "@/utils/request";
  export default {
    name: "src-pages-body-feed-today-v2",
    components: {
      BlogView,
      FeedNav,
	    FreelancerBox,
	    GigBox,
	    PortfolioBox,
      SubCategoryBox,
      OrderBox,
	    helpBox,
	    CompactToday,
	    BuyerRequest
    },
    data() {
      return {
        blogViews: [],
        impressionUUID:null,
      	uploading:false,
	      openShowLineToolTip: false,
      	onlineUsers:{
      		count:0,
          x_count: 0,
          users:[]
        },
	      scrollPosition: 0,

	      searchTerm: '',
	      userSearchResults: [],
	      categorySearchResults: [],
	      isSearching: false,
	      searchResultDone: false,
	      active: false,
	      typingTimer: null,
      	lovedFreelancers:[],
        inspiringGigs:[],
        lastPortfolios:[],
        lovedCategories:[],
        v3Offset: 0,
        v3DisplaySeeMore: true,
        v3seeMoreLoading: false,
        biaInfo: {
          avatar_urls: []
        },
        buyerRequestInfo: [],
        pageLoaded: false,
        blogLists: [
          {
            title: null,
            subtitle: null,
            data: [
              {
                "header": "",
                "subheader": "",
                "subheader2": null,
                "style_type": "",
                "size_type": "A2",
                "slug": "",
                "color": "",
                "renderedLists": [],
                "image_url_list": "https://gcdn.bionluk.com/site/general/blank_gig.png",
                "description": null,
                "footer_div": {},
                "htmlList": []
              }
            ]
          }
        ]
      };
    },

    methods: {
	    setImage() {
		    document.getElementById('files').click();
	    },
	    onFileChange(e) {
		    let files = e.target.files || e.dataTransfer.files;
		    if (!files.length)
			    return;

		    this.$store.commit(this.types.ACTIVE_MODAL, {
			    modalType: this.Modals.CROPPER,
			    info: {file: files[0], canvasPixel: {width: 400, height: 400}, divider: 1, viewMode: 1, fileType: "profileImage"}
		    });
		    document.getElementById("files").value = "";
	    },

	    getCroppedImage(payload) {

	    	this.uploading = true;
		    this.uploadResponseStatus = "started";
		    this.timeStamp = +new Date();
		    this.uploadFileToGoogle(payload.file, this.Constants.UPLOAD_TYPES.AVATAR, 'onboarding', this.timeStamp)
			    .then(result => {
				    this.$store.state.user.avatar_url = this.Constants.CDN_UPLOADS_G + result.uploadName;
				    this.uploadResponseStatus = null;
				    this.uploading = false;
				    this.Helper.trackEvents.customEvent("uploaded_avatar", {version:'v1',whereFrom:this.$store.state.componentMap.page,clickedFrom:'today welcome'});
			    })
			    .catch(err => {
				    this.$toasted.global.infoToast({description: err ? err.message : this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				    this.uploadResponseStatus = null;
				    this.uploading = false;
			    });
	    },
	    copyLine(){

		    let dummy = document.createElement('input');

		    document.body.appendChild(dummy);
		    dummy.value = this.user.info.f_t_line;
		    dummy.select();
		    document.execCommand('copy');
		    document.body.removeChild(dummy);
		    this.$toasted.global.infoToast({description: 'Kopyalandı'});
	    },
	    handleFocusOut(){
       this.openShowLineToolTip = false;
      },
    	showLineToolTip() {
    		this.openShowLineToolTip = true
        this.$nextTick(() => {
          document.getElementById("line-tool-tip").focus()
        })
      },
	    openFreelancerLineFaqModal() {
		    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.FREELANCER_LING_FAQ});
	    },
	    extend(clickedFrom = null) {

	    	this.$store.state.clickedFrom = clickedFrom;
		    this.api.user.useItem(2, this.$Progress)
			    .then(({data}) => {
				    let result = data;
				    if (result.success) {
					    this.api.user.extendLine(this.$Progress)
						    .then(({data}) => {
							    let result = data;

							    if (result.success) {
								    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
								    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
								    this.$store.state.user.info.f_t_status = 3;
								    this.$toasted.global.errorToast({description: result.message});
							    } else {
								    this.$toasted.global.errorToast({description: result.message});
							    }
						    });

				    } else {
					    if (result.data && result.data.ok && result.data.ok === 'wait') {

						    this.$toasted.global.errorToast({description: result.message});
					    } else {
						    const data = {
							    data: {
								    type: "item",
								    total: 12.90,
								    itemId: 2,
								    cards: result.data.cards,
                    walletAmount:result.data.walletAmount
							    }
						    };
						    this.$store.state.clickedFrom = clickedFrom;
						    this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.CHECKOUT, info: {checkOutData: data, isSinglePay: true}});

					    }
				    }
			    });
	    },
	    clicked(what){

	    	if(what === 'bicoin'){
			    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'bicoin up small box'});
			    this.user.info.clicked_bicoin = true;
        } else if(what === 'current_banner'){
			    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'current banner'});

			    this.user.info.clicked_current_banner = true;
		    }

        this.api.feeds.clicked(what)
          .then(({data}) => {
          })
          .catch(err => {
            this.$toasted.error(err);
          })

		    if(what === 'bicoin'){
		    	this.$store.state.clickedFrom = 'up small box'
			    this.$router.push("/bicoin");
		    }
      },

	    deleteLastSeen(id){
	    	if(id === 'all'){
			    this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'delete last items all'});
			    let newList = [];
			    this.$store.state.lastSeenItems = newList;
        } else {
			    let index = -1
          let list = this.$store.state.lastSeenItems;
			    index = list.findIndex(l => l.id === id);
			    if(index > -1){
				    this.$store.state.lastSeenItems.splice(index, 1);
          }
		    }
		    this.api.feeds.deleteLastSeenItem(id)
			    .then(({data}) => {
            this.Helper.trackEvents.customEvent("clicked", {version:'v1',page:this.$store.state.componentMap.page,what:'delete last items single'});
          })
			    .catch(err => {
				    this.$toasted.error(err);
			    })

      },
	    convertTime(value) {
			    let timeStamp = value;
			    let years = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30 / 12;
			    if (years >= 1) {
				    return years.toFixed(0) + " yıl"
			    } else {
				    let months = timeStamp / 1000 / 60 / 60 / 24 / 7 / 30;
				    if (months >= 1) {
					    return months.toFixed(0) + " ay";
				    } else {
					    let weeks = timeStamp / 1000 / 60 / 60 / 24 / 7;
					    if (weeks >= 1) {
						    return weeks.toFixed(0) + " hafta";
					    } else {
						    let days = timeStamp / 1000 / 60 / 60 / 24;
						    if (days >= 1) {
							    return days.toFixed(0) + " gün";
						    } else {
							    let hours = timeStamp / 1000 / 60 / 60;
							    if (hours >= 1) {
								    return hours.toFixed(0) + " saat"
							    } else {
								    let minutes = timeStamp / 1000 / 60;
								    if (minutes >= 1) {
									    return minutes.toFixed(0) + " dakika";
								    } else {
									    let seconds = timeStamp / 1000;
									    return (seconds >= 1 ? seconds.toFixed(0) : 0) + " saniye";
								    }
							    }
						    }
					    }
				    }
			    }
	    },

	    setLastItem(what, item){

	    	if(what === 'upText'){
			    if(item.type === 'profile'){
				    if(item.is_seller){
					    return 'FREELANCER';
				    } else {
					    return 'KULLANICI';
				    }
			    }
			    if(item.type === 'gig'){
			    	return 'İŞ İLANI'
          }
			    if(item.type === 'category'){
				    if(item.search_term){
					    return '"'+item.search_term+"'";
            } else {
					    return 'KATEGORİ';
            }
			    }
        }

	    	if(what === 'title'){
			    if(item.type === 'profile'){
			    	return item.username;
			    }
        }

		    if(what === 'subTitle'){
			    if(item.type === 'profile'){
				    return item.title;
			    }
		    }

		    if(what === 'responseTime'){
			    if(item.type === 'profile'){
				    return this.convertTime(item.response_time);
			    }
		    }

	    },
	    searchGigs(searchTerm, clickedFrom) {
		    if(clickedFrom){
			    this.$store.state.clickedFrom = clickedFrom;
		    }
		    this.searchResultDone = false;
        let url = encodeURI(`/search?term=${encodeURIComponent(searchTerm.toLocaleLowerCase('tr-TR'))}`)
		    this.$router.push(url);
	    },
	    //alttaki 3 function klavyeden 200 ms boyunca birşey girilmezse sonuç gösterebilsin diye yazıldı
	    keyUp() {

	      /*
        if(!this.$store.state.tempActions.firstKeyupMainPageSearch){
	        this.$store.state.tempActions.firstKeyupMainPageSearch = true;
          window.hj('trigger', 'start_search_from_main_page');
          window.hj('tagRecording', ['start_search_from_main_page']);
          sessionStorage.setItem("hj_trig", 'start_search_from_main_page');
	        //this.Helper.initLogrocketManual({searchFromMainPage:true})
        }
        */

		    clearTimeout(this.typingTimer);
		    this.typingTimer = setTimeout(() => this.getAzureResult(), 200);
	    },

	    keyDown() {
		    clearTimeout(this.typingTimer);
	    },

	    getAzureResult() {
		    let searchValue = this.searchTerm.trim().replace(/ +/g, "");
		    if (2 < searchValue.length && searchValue.length < 20) {
			    this.isSearching = true;
			    this.searchResultDone = true;

			    this.searchUsers({term: searchValue})
				    .then(result => {
					    let results = result.results;
					    if (results) {
						    let arr = [];
						    results.forEach(newResult => {
							    if (arr.length < 7) {
								    arr.push(newResult);
							    }
						    });
						    this.userSearchResults = arr;
						    this.isSearching = false;
					    }
				    })
				    .catch(err => {
					    this.userSearchResults = [];
					    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				    });

			    this.searchDeeds2({term: this.searchTerm.trim(), limit: 1, facets: ["subCategorySlug"]})
				    .then(async (results) => {
					    if (results.facets && results.facets.subCategorySlug.length) {
						    let facetedResults = results.facets.subCategorySlug.sort(function (a, b) {
							    return b.count - a.count;
						    });

                const categories = await this.$store.state.categoriesPromise
						    facetedResults.forEach(result => {
                  categories.forEach(category => {
								    if (category.slug === result.value) {
									    result.name = category.name;
									    result.isParent = !category.parent_id;
									    result.id = category.id;
								    }
							    });
						    });

						    this.categorySearchResults = facetedResults.slice(0, 4);
					    }
				    })
				    .catch(err => {
					    this.categorySearchResults = [];
					    this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
				    });
		    } else {
			    this.userSearchResults = [];
			    this.categorySearchResults = [];
			    this.isSearching = false;
			    this.searchResultDone = false;
		    }
	    },
	    inputEnter(searchTerm) {
		    this.searchGigs(searchTerm, 'today enter');
		    this.searchTerm = "";
	    },



	    retrieveOnlineUsers(){
	    	if(!this.user.seller.isSeller && this.user.requestCount){
			    this.api.feeds.getOnlineUsers('count')
				    .then(({data}) => {
					    let result = data;
					    if (result.success) {
						    this.onlineUsers = result.data;
					    }
				    })
				    .catch(err => {
					    this.$toasted.global.errorToast({description: err});
				    });
        }
      },

	    retrieveNewMainPage() {

        this.v3seeMoreLoading = true;
        this.api.feeds.newMainPage()
          .then(({data}) => {
            let result = data;
            this.pageLoaded = true;



            this.v3seeMoreLoading = false;

            if (result.success) {
                this.lovedFreelancers = result.data.lovedFreelancers;
                this.inspiringGigs = result.data.inspiringGigs;
                this.lastPortfolios = result.data.lastPortfolios;
                this.lovedCategories = result.data.lovedCategories;
                this.v3DisplaySeeMore = false;

            } else {
              //this.$router.push(result.data.redirect_url)
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: err});
          });
      },

	    routeSearchUserPage(term) {
		    this.$store.state.clickedFrom = 'today search';
		    this.$router.push(`/usersearch?term=${term}`);
		    this.searchResultDone = false;
	    },

	    routeSearchDeedsPage(searchedCategory, term) {
		    this.$store.state.clickedFrom = 'today search';
        let url = encodeURI(`/search?term=${encodeURIComponent(term.toLocaleLowerCase('tr-TR'))}&slug=${searchedCategory.value}`)
		    this.$router.push(url);
		    this.searchResultDone = false;
	    },
	    goToUserProfile(username) {
		    this.$store.state.clickedFrom = 'today search';
		    this.$router.push(`/${username}`);
		    this.searchResultDone = false;
	    },

	    goToCategory(categoryName, url, isHref = false) {
		    if (!isHref) {
			    let subCategories = document.getElementById(categoryName);
			    subCategories.style.display = "none";
		    }
		    if (url && isHref) {
			    return '/freelancer-bul/' + url;
		    } else if (url && !isHref) {
			    this.$router.push('/freelancer-bul/' + url);
		    }
	    },



      preventClicking(event) {
        event.returnValue = false;
        return false;
      },

    },

	  watch: {

      "$store.state.user.seller": function (newValue, oldValue) {

        console.log('newValue',newValue.portfolioCount)
        console.log('oldValue',oldValue.portfolioCount)

      },
		  "$store.state.windowScrollTop": function (newValue, oldValue) {
			  if (this.seemore && newValue > (this.$store.state.windowScrollHeight - 1200)) {
				  this.loader = true;
				  this.seemore = false;
				  this.retrieveFeeds(this.limit, true);
			  }
			  let currentScrollPosition = newValue;
			  let found = document.getElementsByClassName('feed-sidebar-u')[0];
			  if (currentScrollPosition > this.scrollPosition) {
				  if(found) {
				  	found.style.top = null;
					  //found.style.position = "sticky";
					  found.style.top = "calc(100vh-713px)";
					  //console.log("Scrolling down");
				  }
			  } else {
			  	if(found){
					  found.style.bottom = null;
					  //found.style.top = newValue+"px";
					  found.style.top = "110px";
					  //found.style.position = "relative";
					  //console.log("Scrolling up");
          }

			  }

			  if(!this.user.seller.isSeller){
				  let mainSearchBar = document.getElementById("regularHeaderSearchContainer");
				  if(currentScrollPosition > 370){
					  if(mainSearchBar){
						  mainSearchBar.style.visibility = 'visible';
						  mainSearchBar.style.opacity = '1';
					  }

				  } else {
					  if(mainSearchBar){
						  mainSearchBar.style.visibility = 'hidden';
						  mainSearchBar.style.opacity = '0';
					  }
				  }
        }

			  this.scrollPosition = currentScrollPosition;
		  }
	  },

    computed: {
	    endingDate() {
		    return customDateFormat(this.$store.state.user.info.f_t_ending_at_timestamp, 'dd MMMM yyyy HH:mm')
	    },

	    leftDay() {
        const diff = diffFromNow(this.$store.state.user.info.f_t_ending_at_timestamp, 'days')
        if (diff === 0) {
          return '[bugün bitiyor]';
        } else {
          return '['+diff+' gün kaldı]'
        }
	    },

    	showSellerBox3: function () {
        if(this.user.seller.isSeller && (!this.user.seller.oldGigCount || !this.user.info.title || !this.user.info.brief || !this.user.seller.skillCount || this.user.seller.portfolioCount < 3 || !this.user.seller.isRegistered)){
        	return true
        } else {
        	  return false;
        }
	    },

    },
    created() {

    	//console.log(this.$store.state.user.openOrders);
      this.impressionUUID = this.Helper.generateUUID();

	    this.retrieveOnlineUsers();

	    this.EventBus.$on('cropperImageFile', payload => {
		    if (payload.fileType === "profileImage") {
			    this.getCroppedImage(payload);
		    }
	    });

	    this.EventBus.$on('isSinglePayed', (payload) => {
		    if(payload.isSinglePayed) {

			    this.api.user.extendLine(this.$Progress)
				    .then(({data}) => {
					    let result = data;

					    if (result.success) {
						    this.$store.state.user.info.f_t_ending_at = result.data.f_t_ending_at;
						    this.$store.state.user.info.f_t_ending_at_timestamp = result.data.f_t_ending_at_timestamp;
						    this.$store.state.user.info.f_t_status = 3;
						    this.$toasted.global.errorToast({description: result.message});

					    } else {
						    this.$toasted.global.errorToast({description: result.message});
					    }

				    });

		    } else {
			    this.$toasted.global.errorToast({description: 'Ödeme Başarısız Oldu'});
		    }
	    });

      Api.get('/blog-views/bugun')
        .then(({ data }) => {
          this.blogViews = data
        })

      if(!this.user.openOrders || !this.user.openOrders.length) {
        this.api.user.getOpenOrders()
          .then(({data}) => {
            this.user.openOrders = data.data.orders
          })
      }

      if(!this.user.todoCount ) {
        this.api.user.getTodoCount()
          .then(({data}) => {
            this.user.todoCount = data.data.count
          })
      }
    },
	  beforeDestroy() {
		  this.EventBus.$off("cropperImageFile");
		  this.EventBus.$off('isSinglePayed');
	  }
  }
</script>

<style scoped lang="scss">

  .banner-delete-icon-area{
    cursor: pointer;
    z-index:1; position: absolute; margin-left: 855px; margin-top:16px;
    &:hover{
      opacity: 0.7;
    }
    .banner-delete-icon /deep/ {
      width: 16px;
      height: 16px;

      path {
        fill: #bfc8d2;
      }


    }
  }


  .avatar-upload-area{

    margin-left: 63px;
    cursor: pointer;
    position: absolute;
    border-radius: 50%;

    width: 30px;
    height: 30px;
    border: solid 3px #eaedf2;
    background-color: #fd4056;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      opacity: 0.8;
    }
    svg{
      width: 12px!important;
      height: 12px!important;
    }


    .avatar-upload-icon /deep/ {
      width: 12px;
      height: 12px;
      path {
        fill: #fff;
        &:hover{
          opacity: 0.8;
        }
      }


    }
  }

  .profile-box-items{

    margin-top: 15px;
    margin-bottom: 5px;
    width: 230px;
      svg{
        width: 16px!important;
        height: 16px!important;
      }


      .profile-box-item-ok /deep/ {
        cursor: pointer;
        width: 16px;
        height: 16px;
        path {
          fill: #bfc8d2;
        }
      }

    .profile-box-item-ok-green /deep/ {
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #26ca9a;
      }

    }

      .item-text{
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        color: #bfc8d2;
        margin-left: 7px;
        line-height: 1.3;
      }

    .item-text-green{
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;

      letter-spacing: normal;
      color: #26ca9a;
      margin-left: 7px;
      line-height: 1.3;
    }
  }

  .freelancerAvatar {
    width: 91px;
    height: 91px;
    border-radius: 100%;
    border: 2px solid #eaeaea;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 4;
  }

  .rainDropcontainer {
    position: relative;
    display: block;
    width: 132px;
    height: 132px;
  }


  .firstLayer {
    width: 108px;
    height: 108px;
    border-radius: 100%;
    opacity: 0.1;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 3;
  }

  .secondLayer {
    width: 120px;
    height: 120px;
    border-radius: 100%;
    opacity: 0.08;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 2;
  }

  .thirdLayer {
    width: 132px;
    height: 132px;
    border-radius: 100%;
    opacity: 0.05;
    background-image: linear-gradient(225deg, #7fadf4, #6181f7);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
  }

  .top-right-box{
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff; display: flex; align-items: center; justify-content: center;


    .small-pink-button{
      width: 130px;
      height: 28px;
      border-radius: 2px;
      background-color: #fd4056;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
      color: white;
      font-size: 12px;
      font-weight: 600;
      &:hover{
        opacity: 0.9;
      }
    }
    svg{
      width: 16px!important;
      height: 16px!important;
    }


    .right-info-icon /deep/ {
      margin-left: 5px;
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #2d3640;
        &:hover{
          opacity: 0.8;
        }
      }


    }

    .right-settings-icon /deep/ {
      cursor: pointer;
      width: 16px;
      height: 16px;
      path {
        fill: #bfc8d2;
        &:hover{
          opacity: 0.8;
        }
      }


    }

    .green-link{
      cursor: pointer;
      font-size: 12px; color:#00a575;
      &:hover{
        text-decoration: underline!important;
      }


    }

    .green-small-area{
      min-width: 80px;
      height: 26px;
      border-radius: 2px;
      background-color: #26ca9a;
      font-weight: bold;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 2px;
      span{
        font-weight: normal;
      }
    }
  }


  .fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }



  .search-result-container {
    width: 600px;
    background-color: #ffffff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 87.5px;
    display: flex;
    flex-direction: column;
    left: 37px;
    z-index: 1;
  }

  .search-result-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 19px;
    height: 30px;
    max-width: 600px;
    font-size: 14px;
    font-weight: 500;
    line-height: 2.29;
    text-align: left;
    color: #2d3640;
  }

  .search-result-users-container {
    max-width: 600px;
    padding: 15px 19px 5px 19px;
    background-color: #f4f5f7;
  }

  .search-result-user:hover {
    text-decoration: underline;
  }




  .mainpage-search-container{
    position: relative;
    width: 893px;
    height: 120px;
    border-radius: 10px;
    box-shadow: 0 20px 20px 0 #e9ebf0;
    border: solid 1px #eff3f8;
    background-color: #ffffff; margin-bottom: 40px; display: flex; align-items: center; justify-content: center;

      input{
      width: 580px; font-size: 16px; font-weight: 500; text-indent: 10px;
      height: 54px;
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 5px;
      border: solid 1px rgba(75, 79, 82, 0.2);
    }
  }
  .section-header-today{

    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2d3640;
    span{
      font-weight: bold;
    }
  }

  .section-sub-header-today{
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    margin-top: 9px;
    margin-bottom: 20px;
    color: #5e6b79;
    span{
      font-weight: bold;
    }
  }

  .line-extend{
    cursor: pointer;
    color:#fd4056; font-weight: normal; font-size: 12px; padding-bottom: 2px; padding-left: 5px;
    &:hover{
      text-decoration: underline;
    }
  }

  .line-active{
    width: 80px;
    height: 28px;
    border-radius: 2px;
    background-color: #26ca9a;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #ffffff;
  }

  .line-not-active{
    width: 80px;
    height: 28px;
    border-radius: 2px;
    background-color: #fd4056;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #ffffff;
  }

  .Box3Container{

    display: flex;
    width: 100%;

    justify-content: space-between;
    height: 520px;
    .Box3{
      width: 280px;
      height: 458px;
    }
      .Box3In{

        display: flex;
        align-items: center;
        flex-direction: column;
        width: 280px;
        height: 440px;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
        border: solid 1px #eaedf2;
        background-color: #ffffff;
        transition: .2s;


        .Box3Button{

          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #fff;
          width: 180px;
          height: 42px;
          padding-bottom: 2px;
          border-radius: 2px;
          border: solid 1px rgba(45, 54, 64, 0.2);

          margin-top: 20px;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #2d3640;
          transition: .2s;
          &:hover{
            background-color: #fd4056!important;
            color:#fff
          }
          a{
            text-decoration: none;
            &:hover{
              color:#fff
            }
          }
        }

        &:hover{
          a{
            text-decoration: none;
            color:#fff;
          }
          margin-top: -7px;
          margin-left: -5px;
          width: 292px;
          height: 458px;
          transition: .2s;

          .Box3Button{

            margin-top: 29px;

            transition: .2s;
            background-color: #fd4056!important;
            color:#fff
          }

          .Box3Desc{
            margin-top: 28px;
            transition: .2s;
            color: #2d3640;
          }
        }


        .Box3Header{

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 52px;
          width: 190px;
          margin-top: 25px;
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.44;
          letter-spacing: normal;
          text-align: center;
          color: #2d3640;

          span{
            font-weight: bold;
          }
        }

        img{
          margin-top: 30px;
          height: 120px;
          width: auto;
        }

        .Box3Desc{

          height: 96px;
          width: 240px;
          margin-top: 24px;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.71;
          letter-spacing: normal;
          text-align: center;
          color: #8b95a1;
          transition: .2s;
        }


    }
  }

  a{
    color: #26ca9a;
    text-decoration: none!important;

    .search-tag{
      margin-left: 15px;
      padding-left: 15px; padding-right: 15px; padding-bottom: 2px; display: flex; align-items: center; justify-content: center;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #8b95a1;
      font-size: 13px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8b95a1;
      text-decoration: none!important;
      &:hover{
        cursor: pointer;
        color:#26ca9a;
        border: solid 1px #26ca9a;
        text-decoration: none!important;

      }
    }
    &:hover{
      text-decoration: none!important;
      color: #26ca9a;
    }
  }



  .mainpage-search-button{
    width: 190px; display: flex; align-items: center; justify-content: center;
    height: 54px;
    border-radius: 5px;
    border: solid 1px #8b95a1; font-size: 16px; font-weight: 500; background: white;

    .search-button-text{
      display: flex; padding-left: 18px; align-items: center; justify-content: center; width: 100px; background: no-repeat left url(https://gcdn.bionluk.com/site/icon/aws/search_siyah.svg)
    }

    &:hover{
      .search-button-text{
        background: no-repeat left url(https://gcdn.bionluk.com/site/icon/aws/search_beyaz.svg)
      }
    }

  }






  .settings {
    display: flex;

  }

  .pageContainer {
    display: flex;
    background-color: #f4f5f7 !important;
  }

  .urgent-job-container {
    max-width: 555px;
    height: 70px;
    background-color: #fff;
    border: solid 1px #d7dde3;
    margin-bottom: 18px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .urgent-job-start-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 36px;
    border-radius: 2px;
    background-color: #fd4056;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border: 0;
    margin-right: 20px;
    text-decoration: none;
  }

  .urgent-job-start-button-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 36px;
    border-radius: 2px;
    background-color: #fd4056;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    border: 0;
    text-decoration: none;
  }

  .urgent-job-info-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  .urgent-job-info-container-status {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-top: 22px;
  }

  .urgent-job-info-icon {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }

  .urgent-job-info-text-container {
    display: flex;
    flex-direction: column;
  }

  .urgent-job-info-text-header {
    font-size: 16px;
    font-weight: 600;
    color: #2d3640;
  }

  .urgent-job-info-text-online {
    font-size: 14px;
    color: #6dce6a;
  }

  .urgent-job-freelancers-list {
    display: flex;
    padding: 0;
    list-style-type: none;
  }

  .urgent-job-freelancers-list-item {
    display: flex;
    margin-right: -10px;
    &:last-child {
      .urgent-job-freelancers-list-item-detail {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
      }
    }
  }

  .urgent-job-freelancers-list-item-detail {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .urgent-job-freelancers-list-item-detail-image {
    width: 36px;
    object-fit: cover;
    border: 2px solid #FFF;
    border-radius: 50%;
  }

  .urgent-job-freelancers-list-item-detail-count {
    font-size: 12px;
    letter-spacing: -1px;
    color: #2d3640;
  }

  .feed-request-container {
    width: 250px;
    height: 176px;
    background-color: #fff;
    border: solid 1px #d7dde3;
    display: flex;
    flex-direction: column;
  }

  .feed-request-text {
    padding: 30px 0 15px 17px;
    font-size: 14px;
    color: #2d3640;
  }

  .feed-request-text-username {
    font-weight: bold;
  }

  .feed-request-landing-text {
    padding-left: 17px;
    font-size: 16px;
    line-height: 1.38;
    color: #8b95a1;
  }

  .feed-request-button-container {
    display: flex;
    justify-content: center;
    padding: 12px 0;
  }

  .feed-request-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 225px;
    height: 36px;
    border-radius: 2px;
    background-color: #2d3640;

    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    text-decoration: none;
  }

  .feed-request-advice {
    width: 250px;
    height: 67px;
    background-color: #f9f9f9;
    border: solid 1px #d7dde3;
    //border-top: 0;
    border-bottom: 0;
    display: flex;
  }

  .feed-request-advice-text {
    font-size: 14px;
    color: #8b95a1;
    padding: 16px 18px 23px 17px;
  }

  .feed-request-advice-text-count {
    color: #fd4056;
    text-decoration: underline;
    display: inline-block;
  }

  .feed-urgent-job-status-container {
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: solid 1px #d7dde3;
    margin-top: 10px;
  }

  .urgent-job-button-freelancer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .urgent-job-freelancers-container-status {
    padding: 15px 0;
  }

  .urgent-job-switch-text-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }

  .urgent-job-switch-text-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  .urgent-job-switch-text {
    font-size: 16px;
    color: #2d3640;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 23px;
    margin-right: 20px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 19px;
    width: 19px;
    left: 1.5px;
    bottom: 1.5px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .checkedSwitch + .slider {
    background-color: #45bf13;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #45bf13;
  }

  .checkedSwitch + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 20px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .urgent-job-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 250px;
    height: 80px;
    background-color: #f9f9f9;
    border: solid 1px #d7dde3;
    //border-top: 0;
  }

  .frequently-asked {
    color: #fd4056;
    padding: 2px 4px;
    cursor: pointer;
  }

  .urgent-job-start-button-container-status {
    margin-bottom: 30px;
  }

  .message-functions {
    display: flex;
    align-items: center;
    margin-top: 14px;
    height: 17px;
    p {
      font-size: 12px;
      color: #8b95a1;
      cursor: pointer;
    }
  }

  .comments-list-div {
    display: block;
    padding-top: 20px;
    border-bottom: solid 1px #eaedf2;
    &:last-child {
      border-bottom: none;
    }
  }

  .message-like-count {
    margin-left: 9px;
  }




  .frequently-asked-container {
    width: 250px;
    height: 30px;
    background-color: #f9f9f9;
    border: solid 1px #d7dde3;
    border-top: 0;
    display: flex;
    align-items: center;
  }

  .report-section {
    position: absolute;
    right: 0;
    top: 20px;
    background-color: #fff;
    border: 1px solid #d7dde3;
    padding: 10px 10px 0px 25px;
    width: 120px;
    p {
      text-align: left;
      margin-bottom: 10px;
      cursor: pointer;
      &:hover {
        color: #fd4056;
      }
    }
  }

  .generalCard {
    text-align: left;
    position: relative;
    cursor: pointer;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
  }

  .generalCard:hover {

    box-shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.2);
    text-decoration: none !important;
  }

  .cardA1 {
    margin-left: -40px;
    width: 459px;
    height: 355px;
    background-position: center;
    background-size: 459px 355px;
  }

  .cardA2 {
    width: 289px;
    height: 355px;

  }

  .cardB1 {

    margin-left: -40px;
    width: 289px;
    height: 355px;
    background-position: center;
    background-size: 289px 355px;

  }

  .cardB2 {
    width: 459px;
    height: 355px;
    background-position: center;
    background-size: 459px 355px;

  }

  .freelancer-list {
    margin-left: 18px;
    margin-right: 18px
  }

  .freelancer-list-div {
    display: flex;
    height: 66px;
  }

  .freelancer-list-div-div {
    align-self: center;
  }

  .freelancer-list-avatar {
    height: 48px;
    border: 1px solid #dde3eb;
    border-radius: 10px;
  }

  .freelancer-list-second-flex {
    margin-left: 15px;
    height: 48px;
    align-self: center
  }

  .freelancer-list-username {
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    color: rgb(36, 36, 36);
    opacity: 0.6;
  }

  .freelancer-list-username_s {
    line-height: 23px;
    font-size: 17px;
    font-weight: 500;
    color: rgb(36, 36, 36);
  }

  .freelancer-list-user-title {
    line-height: 16px;
    font-size: 13px;
    font-style: italic;
    color: rgb(139, 149, 161);
  }

  .freelancer-list-border {
    margin-left: 100px;
    border-bottom: 1px solid #e1e1e1;
  }

  .freelancer-list-border_s {
    margin-left: 60px;
    border-bottom: 1px solid #e1e1e1;
  }

  .card_bold .title {

    font-size: 40px;
    bottom: 64px;
    left: 30px;
    position: absolute;
    z-index: 1;
    font-weight: 800;
    shadow: 0 15px 20px 0 rgba(40, 60, 98, 0.1);
    width: 100%;
    word-break: break-all;
    text-wrap: normal;
  }

  .card_bold .subheader {

    font-weight: 400;
    opacity: 0.7;
    font-size: 20px;
    line-height: 1.56;
    position: absolute;
    bottom: 28px;
    left: 30px;
  }

  .card_up_title .title {

    font-size: 23px;
    font-weight: 600;
    padding-left: 20px;

    letter-spacing: 0.34px;
    text-align: left;
    line-height: 26px;
  }

  .card_up_title .subheader {

    letter-spacing: -0.24px;
    text-transform: uppercase;
    padding: 18px 20px 6px 20px;
    font-size: 15px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 18px;
  }

  .card_up_title .subheader2 {

    position: absolute;
    bottom: 15px;
    left: 20px;
    font-size: 14px;
    opacity: 0.95;

    letter-spacing: -0.32px;
    line-height: 19px;
  }

  .card_list .title {
    font-size: 24px;
    font-weight: 600;
    padding-left: 20px;
    letter-spacing: 0.34px;
    text-align: left;
    line-height: 30px;
  }

  .card_list .subheader {
    font-size: 15px;
    letter-spacing: -0.24px;
    text-transform: uppercase;
    padding: 18px 20px 6px 20px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 18px;
  }

  .card_list .subheader2 {
    position: absolute;
    bottom: 20px;
    left: 20px;
    font-size: 14px;
    opacity: 0.7;
    letter-spacing: -0.32px;
    line-height: 19px;
  }

  .bottom_text_placeholder {
    position: absolute;
    bottom: 16px;
    left: 20px;
    right: 20px;
  }

  .card_bottom_title .subheader {
    z-index: 1;
    text-transform: uppercase;
    font-size: 16px;
    opacity: 0.75;
    font-weight: 500;
    line-height: 19px;
  }

  .card_bottom_title .title {
    font-size: 23px;
    font-weight: 600;
    line-height: 29px;
  }

  .card_bottom_title .subheader2 {
    padding-top: 16px;
    font-size: 15px;
    z-index: 1;
    letter-spacing: -0.32px;
    line-height: 21px;
    opacity: 0.6;
  }

  .white {
    color: #fff;
  }

  .black {
    color: #242424;
  }

  .card_date_line {
    display: flex;
    width: 100%;
    height: 20px;
    margin-top: 10px;
    align-content: baseline;
    align-items: baseline;
    align-self: baseline
  }

  .card_date_line_left {
    padding-left: 10px;
    flex: 1;
    color: #2d3640;
    font-size: 32px;
    font-weight: 600;
    text-align: left
  }

  .card_date_line_right {
    flex: 1;
    font-size: 18px;
    font-weight: 500;
    text-align: right;
    color: #8b95a1;
    padding-right: 24px;
  }

  .divA2 {
    margin-left: 40px;
  }

  .divB2 {
    margin-left: 40px;
  }

  /*loader parts*/

  @keyframes loading {
    10% {
      opacity: .4;
    }
    2% {
      opacity: .1;
    }
  }

  .online-users-loader{
    animation-name: loading;
    animation-duration: 1.75s;
    animation-iteration-count: infinite;
  }
  .online-users-loader-2{
    animation-name: loading;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }
  .online-users-loader-3{
    animation-name: loading;
    animation-duration: 2.25s;
    animation-iteration-count: infinite;
  }
  .blog-loader {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .blog-loader-inner {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 788px;
  }

  .blog-loader-1 {
    display: flex;
    flex-direction: column;
    width: 419px;
    height: 315px;
    background-color: #e1e6ed;
    border-radius: 16px;
    padding: 20px;
  }

  .blog-loader-2 {
    display: flex;
    flex-direction: column;
    width: 249px;
    height: 315px;
    background-color: #e1e6ed;
    border-radius: 16px;
    padding: 20px;
  }

  .blog-header-loader {
    width: 300px;
    height: 25px;
    background-color: #bdc5d0;
  }

  .blog-small-header-loader {
    width: 200px;
    height: 25px;
    background-color: #bdc5d0;
  }

  .blog-title-loader {
    width: 220px;
    height: 20px;
    background-color: #bdc5d0;
    margin-top: 10px;
  }

  .blog-avatar-loader {
    width: 50px;
    height: 50px;
    background-color: #bdc5d0;
    margin-right: 10px;
    border-radius: 10px;
  }

  .feed-request-loader {
    width: 212px;
    height: 142px;
    background-color: #e1e6ed;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .feed-urgent-loader {
    width: 232px;
    height: 142px;
    background-color: #e1e6ed;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .feed-job-switch-loader {
    width: 212px;
    height: 73px;
    background-color: #e1e6ed;
    display: flex;
    justify-content: space-between;
    padding: 20px;
  }

  .clickDiv1 {
    margin-left: 40px;
  }

  .mainpage-learnmore-div {

    font-size: 14px;
    width: 120px;
    height: 40px;
    border-radius: 2px;
    font-size: 16px;

    border: 1px solid rgba(75, 79, 82, .2);
    cursor: pointer;

    margin: auto;
  }

  .mainpage-learnmore-div {
    width: 220px;
    height: 50px;
    border-radius: 2px;

    border: 1px solid rgba(75, 79, 82, .2);

    margin: auto;
  }

  .mainpage-learnmore-div:hover {
    width: 220px;
    height: 50px;
    border-radius: 2px;
    border: solid 1px rgba(75, 79, 82, 0.2);
    margin: auto;
  }

  .mainpage-learnmore-div a {
    font-size: 16px;
    font-weight: 500;
    color: rgb(45, 54, 64);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    text-decoration: none;
  }


  .tumu{
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #00a575;
    padding-right: 10px;
    padding-top: 12px;
    &:hover{
      text-decoration: underline!important;
      color: #00a575;
    }
  }
  .last-seen-box-area{
    transition: 1s;
    display: flex;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;


    .last-seen-box-container{
      margin-right: 27px;
      width: 280px;
      height: 115px;
      border-radius: 2px;
      background-color: #ffffff;
      &:last-child{
        margin-right: 0px;
      }
    }


    .last-seen-box{

      padding: 15px 15px 22px 15px;

      .last-seen-profile{
        display: flex;
        margin-top: 8px;
        height: 52px;


        .last-seen-avatar{
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: solid 2px #eaeaea;
        }

        .last-seen-gig-img{
          width: 88px;
          height: 50px;
          border-radius: 5px;
          border: solid 1px #eaeaea;
        }

        .last-seen-info-area{
          margin-left: 10px;
          display: flex;
          flex-direction: column;
          height: 56px;
        }
      }



      .last-seen-header{
        display: flex;
        justify-content: space-between;
      }


      .last-seen-delete{

        cursor: pointer;
        &:hover{
          opacity: 0.8;
        }

        svg{
          width: 16px!important;
          height: 16px!important;
        }

        .last-seen-delete-icon /deep/ {
          width: 16px;
          height: 16px;
          path {
            fill: #bfc8d2;
          }
        }
      }
    }

    .up-text{
      font-size: 11px;

      color: #8b95a1;
    }

    .m-title{
      font-size: 14px;
      color: #2d3640;

      text-overflow: ellipsis;
      white-space: pre;
      width: 160px;
      overflow: hidden;
      height: 16px;

    }

    $title-font-size: 14px;
    $title-line-height: 1.25;
    $title-lines-to-show: 2;
    .m-title-gig{

      color: #2d3640;
      display: -webkit-box;
      -webkit-line-clamp: $title-lines-to-show;
      -webkit-box-orient: vertical;
      width: 150px!important;
      font-size: $title-font-size;
      line-height: $title-line-height;
      height: $title-font-size*$title-line-height*$title-lines-to-show; /* Fallback for non-webkit */
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 2px;

    }

    .m-sub-title{
      text-overflow: ellipsis;
      white-space: pre;
      width: 160px;
      overflow: hidden;
      height: 13px;

      font-size: 11px;
      margin-top: 5px;
      color: #5e6b79;
    }

    .green-text{
      margin-top: 5px;
      font-size: 12px;
      font-weight: 500;
      color: #00a575;

      span{
        font-weight: bold;
      }
      &:hover{
        text-decoration-color: #00a575!important;
      }

    }


    .down-text-area{

      display: flex;
      align-items: center;
      margin-top: 2px;

      .down-text-icon-area{
        margin-right: 5px;
        svg{
          width: 12px!important;
          height: 12px!important;
        }

        .down-text-icon /deep/ {
          width: 12px;
          height: 12px;
          path {
            fill: #8b95a1;
          }
        }
      }
      .down-text{
        font-size: 10px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        color: #8b95a1;
        span{
          font-size: 10px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #2d3640;
        }
      }
    }

  }



  .line-tool-tip {


    outline: none;
    display: block;
    visibility: hidden;
    position: absolute;

    width: 193px;
    height: 135.1px;
    opacity: 0.95;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.9);

    color: #fff;
    left: 49px;
    top: -82px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    z-index: 100;

  }

  .line-tool-tip::after {
    content: " ";
    position: absolute;
    left: 166px;
    top: 100%;
    border-width: 7px;
    border-style: solid;
    border-color: #2d3640 transparent  transparent transparent;
  }
</style>
